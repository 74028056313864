import React from "react";
import CoursesAll from "./CoursesAll";
import CourseFacility from "./CourseFacility";
import { ReviewSlide } from "../../global_components/Extra-Components/Review/Review";
import WebBanner from "../../global_components/Extra-Components/WebBanner.js/WebBanner";
import StudentInquries from "../Inquries/StudentInquries";
const Courses = () => {
  return (
    <div className="custom-container">
      <WebBanner
        pageTitle={`COURSES`}
        title={`Building Scalable Web Apps : Practical Guide`}
        highlightText={["Practical Guide"]}
      />
      <div className="container-md">
        <CoursesAll />
      </div>
      <CourseFacility />
      <div className="container-md">
        <ReviewSlide
          type={2}
          minText={`STUDENT REVIEWS`}
          text={`What Students say about Us`}
        />
      </div>
      <StudentInquries />
    </div>
  );
};

export default Courses;
