import React, { useEffect, useState } from "react";
import Title from "../../Extra/Title/Title";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { webTechnologyGet } from "../../../redux/slice/webTechnologySlice";
import { baseURL } from "../../../utils/config";

const TechnologyHome = () => {
  const { webTechnology } = useSelector((state) => state.webTechnology);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(webTechnologyGet());
  }, []);

  useEffect(() => {
    setData(webTechnology);
  }, [webTechnology]);

  return (
    <section className="technologyHome p-100-y text-center bg-third">
      <div className="container-md">
        <div className="row">
          <div className="col-xl-5 col-sm-6 col-12 order-sm-0 order-1">
            <div className="technologyDetails">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-6">
                  {data.map(
                    (res, i) =>
                      i % 2 == 0 && (
                        <TechnologyCategories
                          image={res.image}
                          title={res.technologyName}
                          color={res.technologyColor}
                          key={i}
                        />
                      )
                  )}
                </div>
                <div className="col-lg-6 col-6 m-sm-100-top">
                  {data.map(
                    (res, i) =>
                      i % 2 == 1 && (
                        <TechnologyCategories
                          image={res.image}
                          title={res.technologyName}
                          color={res.technologyColor}
                          key={i}
                        />
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-sm-6 col-12 order-sm-1 order-0">
            <div className="position-sticky top-25">
              <Title
                minText={`OUR EXPERTISE`}
                text={`Our Technology Stack`}
                textAlign={`text-sm-start text-center`}
                fontsClass={`text-light`}
              />
              <div className="text-gray text-sm-start text-center fs-lg-16 fs-14 lh-35 m-xl-150-left m-sm-50-left m-sm-50-top m-10-top m-sm-0-bottom m-50-bottom">
                At the core of our success lies a deep commitment to innovation,
                precision, and excellence. With years of experience across
                diverse industries, we specialize in delivering tailored
                solutions that drive growth, efficiency, and transformation. Our
                team of experts brings a wealth of knowledge, ensuring that
                every project is executed with the highest standards of quality
                and professionalism.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechnologyHome;

const TechnologyImage = styled.div`
  .catrgoryOfTechnology {
    .technologyCategory {
      background-color: ${(props) => props.color};
    }
    &:hover {
      ${"" /* box-shadow: 0 0px 26px ${(props) => `${props.color}50`}; */}
      border-color: transparent !important;
      background-color: ${(props) => props.color};
      .technologyCategory {
        &::before {
          ${"" /* border: 3px solid ${(props) => props.color}; */}
          border: 3px solid #fff};
        }
      }
    }
  }
`;
export const TechnologyCategories = ({ image, title, color }) => {
  return (
    <TechnologyImage color={color}>
      <div className="catrgoryOfTechnology cursor-pointer border-solid-second-1 border-radius-20 p-30-y h-100 p-30-x trans-3 m-30-bottom">
        <div
          className="technologyImage technologyCategory hw-xl-110 hw-70 p-xl-25 p-20 border-round-50 m-auto"
          style={{
            backgroundColor: color,
          }}
        >
          <img src={baseURL + image} alt="technology" />
        </div>
        <div className="fw-600 fs-xxl-20 fs-xl-18 fs-16 m-xl-30-top m-sm-20-top m-15-top text-light">
          {title}
        </div>
      </div>
    </TechnologyImage>
  );
};
