import React, { useEffect, useState } from "react";
import "./scrollTop.scss";

const ScrollToTop = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [scrollCount, setScrollCount] = useState(0);

  // Track scroll progress
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      setScrollCount(scrollTop);
      const progress = scrollHeight > 0 ? (scrollTop / scrollHeight) * 100 : 0;
      setScrollProgress(progress);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollCount]);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Circle circumference
  const circleCircumference = 307.919;
  const strokeDashoffset = circleCircumference * (1 - scrollProgress / 100);

  return (
    <>
      <div
        className={`navigationIcon scroll-to-top navIcon position-fixed top-30 right-0 z-1 hw-55 cursor-pointer d-block border-radius-50 d-flex justify-content-center align-items-center toggle ${
          scrollCount > 100 && "active-progress"
        }`}
        // style={{ transform: "translateY(-100%)" }}
      ></div>
      <div
        className={`scroll-to-top position-fixed bottom-30 left-30 z-1 hw-55 cursor-pointer d-block border-radius-50 ${
          scrollCount > 500 && "active-progress"
        }`}
        onClick={scrollToTop}
        title="Scroll to Top"
      >
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            style={{
              transition: "stroke-dashoffset 10ms linear",
              strokeDasharray: `${circleCircumference}, ${circleCircumference}`,
              strokeDashoffset,
            }}
          />
        </svg>
      </div>
    </>
  );
};

export default ScrollToTop;
