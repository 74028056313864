import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../axiosApi";

const initialState = {
  webContactFullData: [],
  webContactIconData: [],
  isLoading: false,
  isSkeleton: false,
};
export const getWebContact = createAsyncThunk(
  "webContact/getWebContact",
  async (payload) => {
    return apiInstance.get(`webContact/getWebContact`);
  }
);

const webContactSlice = createSlice({
  name: "webContactSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getWebContact
    builder.addCase(getWebContact.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(getWebContact.fulfilled, (state, action) => {
      state.webContactFullData = action.payload.webContactFullData;
      state.webContactIconData = action.payload.webContactIconData;
      state.isSkeleton = false;
    });
    builder.addCase(getWebContact.rejected, (state, action) => {
      state.isSkeleton = false;
    });
  },
});
export default webContactSlice.reducer;
