import React from "react";
import "./home.scss";
import AboutHome from "./AboutHome";
import CourseHome from "./CourseHome";
import ServiceHome from "./ServiceHome";
import TechnologyHome from "./TechnologyHome";
import TopSlider from "./TopSlider";
import ClientInquires from "../Inquries/ClientInquries";

const Home = () => {
  return (
    <div>
      <TopSlider />
      <AboutHome />
      <ServiceHome />
      <CourseHome />
      <TechnologyHome />
      <ClientInquires />
    </div>
  );
};

export default Home;
