import React from "react";
import Title from "../../Extra/Title/Title";
import Button from "../../Extra/Button/Button";
import Input, {
  clearFormFields,
  submitData,
  Textarea,
} from "../../Extra/Inputs/Input";
import { createWebMessages } from "../../../redux/slice/webInquiresSlice";
import { useDispatch } from "react-redux";

const ClientInquires = () => {
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    let addWebMessages = submitData(e);
    addWebMessages = { ...addWebMessages, type: 2 };
    if (addWebMessages) {
      try {
        dispatch(createWebMessages(addWebMessages)).unwrap();
        clearFormFields("contactForm");
      } catch (err) {
        throw err;
      }
    }
  };

  return (
    <section className="contectHome p-100-y text-center">
      <div className="container-md">
        <Title
          minText={`CONTACT US`}
          text={`Connect with Us and Create Something Amazing`}
        />
        <div className="contectDetails text-center m-100-top">
          <form id="contactForm" onSubmit={(e) => handleSubmit(e)}>
            <div className="row justify-content-center">
              <div className="col-sm-4 col-12 m-30-bottom">
                <Input
                  type={`text`}
                  name={`firstName`}
                  id={`firstName`}
                  placeholder={`First Name`}
                  errorMessage={`Enter Your First Name`}
                />
              </div>
              <div className="col-sm-4 col-12 m-30-bottom">
                <Input
                  type={`text`}
                  name={`lastName`}
                  id={`lastName`}
                  placeholder={`Last Name`}
                  errorMessage={`Enter Your Last Name`}
                />
              </div>
              <div className="col-sm-4 col-12 m-30-bottom">
                <Input
                  type={`text`}
                  name={`email`}
                  id={`email`}
                  placeholder={`Email`}
                  errorMessage={`Enter Your Email`}
                />
              </div>
              <div className="col-12 m-30-bottom">
                <Textarea
                  name={`message`}
                  id={`message`}
                  row={6}
                  placeholder={`Message`}
                  errorMessage={`Enter Your Message`}
                />
              </div>
              <div className="col-12">
                <Button text={`Send Message`} className={`m-50-top`} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ClientInquires;
