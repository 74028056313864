import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/main_logo_white.png";
import $ from "jquery";
import Button from "../../Extra/Button/Button";
import { useSelector } from "react-redux";

const Navigation = () => {
  const navigate = useNavigate();
  const { webContactIconData } = useSelector((state) => state.webContact);
  const navigatorArray = [
    {
      name: "home",
      url: "/",
    },
    {
      name: "about us",
      url: "/about",
    },
    {
      name: "courses",
      url: "/courses",
    },
    {
      name: "portfolio",
      url: "/portfolio",
    },
    {
      name: "blog",
      url: "/blog",
    },
    {
      name: "Products",
      url: "/products",
    },
    {
      name: "contact us",
      url: "/contact",
    },
  ];

  return (
    <div className="navigation">
      <NavigationScript />
      <div className="container-md">
        <div className="d-flex justify-content-between p-20-y">
          <Link to="/">
            <div className="logo height-60">
              <img className="logoBar h-100" src={logo} alt="logos" />
            </div>
          </Link>
          <div className="toggle text-light fs-25 d-flex align-items-center">
            <i
              className="ri-bar-chart-horizontal-line cursor-pointer"
              style={{ transform: "rotate(180deg)" }}
            ></i>
          </div>
          <div className="blackBg toggle closeBg position-fixed top-0 left-0 z-2 trans-3 h-100 w-100"></div>
          <div className="mainNavigation closeNavigation position-fixed top-sm-3p top-0 right-sm-6p right-0 z-2 trans-3">
            <div className="startNavigation border-solid-gray-1 p-sm-40 p-20-x p-40-y border-radius-sm-20 overflow-auto">
              <ul className="mainMenu">
                {navigatorArray.map((res, index) => (
                  <li key={index}>
                    <Link
                      to={res.url}
                      className="fs-lg-40 lh-lg-70 fs-md-30 lh-md-60 fs-30 lh-50 fw-700 text-light trans-3 text-uppercase cursor-pointer"
                    >
                      <span>{res.name}</span>
                      <i className="ri-arrow-right-up-line"></i>
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="closeNav toggle trans-3 cursor-pointer hw-lg-60 hw-50 border-solid-light-1 border-round-50 d-flex align-items-center justify-content-center text-light position-absolute top-25 right-25">
                <i className="ri-close-large-fill"></i>
              </div>
              <div className="bottomNavigation m-20-top d-flex align-items-center align-items-between">
                <Button
                  text={`For Your Inquire`}
                  className={`inquireBtn toggle`}
                  onClick={() => navigate("/inquires")}
                />
                <div className="companySocialMedia d-flex flex-sm-row flex-column justify-content-sm-start justify-content-center p-60-left position-sm-static position-absolute right-15 bottom-30">
                  {webContactIconData?.map((res, i) => (
                    <Link to={res.dairectLink} target="_blank" key={i}>
                      <div className="socialMediaBox trans-3 cursor-pointer m-10-right m-10-bottom hw-lg-60 hw-50 border-solid-light-1 border-round-50 d-flex align-items-center justify-content-center">
                        <i className={res.icon}></i>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;

export const NavigationScript = () => {
  useEffect(() => {
    const handleNavigation = () => {
      $(".mainNavigation").toggleClass("openNavigation closeNavigation");
      $(".blackBg").toggleClass("openBg closeBg");
    };

    $(".toggle,.mainMenu li a").on("click", handleNavigation);

    return () => {
      $(".toggle,.mainMenu li a").off("click", handleNavigation);
    };
  }, []);

  return null;
};
