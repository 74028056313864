import React from "react";
import About1 from "../../../assets/images/about1.png";
import Title from "../../Extra/Title/Title";
import Highlights from "../../Extra/Highlights";

const AboutHome = () => {
  return (
    <section className="homeAboutUs p-100-y ">
      <div className="container-md">
        <div className="startHomeAboutUs ">
          <div className="row m-40-bottom">
            <div className="col-12 m-auto">
              <Title
                minText={`ABOUT US`}
                text={`Innovating Web Development and IT Education`}
                className={`ls-1`}
              />
            </div>
          </div>

          <div className="multiAbout position-relative">
            <div className="row justify-content-center m-20-bottom">
              <div className="col-xxxl-6 col-xl-6 col-md-10 col-12">
                <div className="homeAboutImg position-relative">
                  <div className="aboutImageLayer">
                    <img src={About1} alt="AboutUs" />
                  </div>
                </div>
              </div>
              <div className="col-xxxl-6 col-xl-6 col-md-10 col-12">
                <div className="homeAboutText p-20">
                  <Title
                    text={`Where Technology Meets Creativity: Web Solutions and IT Education for a Digital World`}
                    textAlign={`text-start`}
                    fonts="fs-xxl-40 lh-xxl-60 fs-xl-30 lh-xl-50 fs-25 lh-40  fw-800"
                    highlights={["Technology", "Web Solutions", "IT Education"]}
                    textCase={true}
                  />
                  <div className="aboutDetail m-30-top">
                    <Highlights
                      text="At Multikod Infosys and Advanced Training, we are dedicated to driving innovation and excellence in the digital world. With a passion for creativity and technology, we offer a comprehensive range of services, including Website Design and Development, Graphics Design, UI/UX Design, Admin Panel, and more. Our mission is to help businesses and individuals thrive in the digital landscape by delivering tailored solutions that meet their unique needs"
                      highlights={[
                        "Multikod Infosys and Advanced Training",
                        "Website Design",
                        "Development",
                        "Graphics Design",
                        "UI/UX Design",
                        "Admin Panel",
                      ]}
                      className={`text-second fs-xxl-18 fs-xl-16 fs-lg-14 fs-sm-16 fs-14 lh-xxl-38 lh-xl-30 lh-lg-26 lh-sm-30 lh-26 fw-500 m-20-bottom`}
                    />

                    <div className="countAboutData m-50-top">
                      <div className="row">
                        <div className="col-12">
                          <CountAbout
                            number={`50+`}
                            text={`Projects Completed`}
                          />
                        </div>
                        <div className="col-12">
                          <CountAbout
                            number={`100%`}
                            text={`Customer Satisfaction`}
                          />
                        </div>
                        <div className="col-12">
                          <CountAbout
                            number={`30+`}
                            text={`Student Placement`}
                          />
                        </div>
                        <div className="col-12">
                          <CountAbout
                            number={`100%`}
                            text={`Learning Guaranty`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHome;

export const CountAbout = ({ number, text }) => {
  return (
    <div className="startCountPage d-flex align-items-center justify-content-between position-relative m-60-bottom">
      <div className="countNumber fs-xxl-60 fs-lg-55 fs-md-40 fs-35 fw-800 text-second">
        {number}
      </div>
      <div className="countText fs-xxl-30 fs-lg-25 fs-320 fw-800 text-primeDark m-sm-10-left m-5-left">
        {text}
      </div>
      <div className="borderLine position-absolute bottom--10 left-0 w-100 height-3 bg-lightGray"></div>
    </div>
  );
};
