import React from "react";
import WebBanner from "../../global_components/Extra-Components/WebBanner.js/WebBanner";
import StudentInquries from "./StudentInquries";

const Inquires = () => {
  return (
    <div>
      <WebBanner
        pageTitle={`INQUIRES`}
        title={`Get Started in IT: Course Inquiries`}
        highlightText={["Course Inquiries"]}
      />
      <StudentInquries />
    </div>
  );
};

export default Inquires;
