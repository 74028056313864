import React, { useState, useEffect } from "react";

const Highlights = ({
  text,
  highlights = [],
  chnageIndex,
  className,
  textClass,
  isAnime,
  highlightColor = "text-prime",
}) => {
  const [displayedText, setDisplayedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (displayedText != "" && currentIndex > 0) {
      setCurrentIndex(0);
      setDisplayedText("");
    }
  }, [chnageIndex]);

  useEffect(() => {
    if (isAnime) {
      if (currentIndex < text.length) {
        const timeout = setTimeout(() => {
          setDisplayedText((prev) => prev + text[currentIndex]);
          setCurrentIndex((prev) => prev + 1);
        }, 50); // Adjust typing speed here (100ms per letter)
        return () => clearTimeout(timeout);
      }
    }
  }, [currentIndex, text, chnageIndex]);

  const parts = (isAnime ? displayedText : text)?.split(
    new RegExp(`(${highlights.join("|")})`, "g")
  );

  return (
    <h2 className={`text-second ${className}`}>
      {isAnime ? (
        <>
          <div className={`position-absolute top-0 left-0 w-100 ${textClass}`}>
            {parts.map((part, index) =>
              highlights.includes(part) ? (
                <span key={index} className={highlightColor}>
                  {part}
                </span>
              ) : (
                part
              )
            )}
          </div>
          <div className="opacity-0">{text}</div>
        </>
      ) : (
        <div className={`${textClass}`}>
          {parts?.map((part, index) =>
            highlights.includes(part) ? (
              <span key={index} className={highlightColor}>
                {part}
              </span>
            ) : (
              part
            )
          )}
        </div>
      )}
    </h2>
  );
};

export default Highlights;
