import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../axiosApi";
import { setToast } from "../../utils/toast";

const initialState = {
  webClientReviews: [],
  webStudentReviews: [],
  isLoading: false,
  isSkeleton: false,
};
export const webClientReviewsGet = createAsyncThunk(
  "webReviews/webClientReviewsGet",
  async (payload) => {
    return apiInstance.get(`webReviews/webReviewsGetForWeb?webReviewsType=1`);
  }
);
export const webStudentReviewsGet = createAsyncThunk(
  "webReviews/webStudentReviewsGet",
  async (payload) => {
    return apiInstance.get(`webReviews/webReviewsGetForWeb?webReviewsType=2`);
  }
);
export const webReviewsAdd = createAsyncThunk(
  "webReviews/webReviewsAdd",
  async (payload) => {
    return apiInstance.post("webReviews/webReviewsAdd", payload);
  }
);
const webReviewsSlice = createSlice({
  name: "webReviewsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // webClientReviewsGet;
    builder.addCase(webClientReviewsGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(webClientReviewsGet.fulfilled, (state, action) => {
      state.webClientReviews = action.payload.webReviews;
      state.isSkeleton = false;
    });
    builder.addCase(webClientReviewsGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webStudentReviewsGet
    builder.addCase(webStudentReviewsGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(webStudentReviewsGet.fulfilled, (state, action) => {
      state.webStudentReviews = action.payload.webReviews;
      state.isSkeleton = false;
    });
    builder.addCase(webStudentReviewsGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // webReviewsCreate
    builder.addCase(webReviewsAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(webReviewsAdd.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        setToast("success", "Review submitted Suucessfully !");
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(webReviewsAdd.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webReviewsSlice.reducer;
