import React from "react";
import WebBanner from "../../global_components/Extra-Components/WebBanner.js/WebBanner";
import Title from "../../Extra/Title/Title";
import { Link } from "react-router-dom";
import GoogleMap from "./GoogleMap";
import ClientInquires from "../Inquries/ClientInquries";
import { useSelector } from "react-redux";

const Contact = () => {
  const { webContactFullData } = useSelector((state) => state.webContact);

  return (
    <div>
      <WebBanner
        pageTitle={`CONTACT US`}
        title={` Get in Touch for Digital Solutions`}
        highlightText={["Digital Solutions"]}
      />
      <section className="serviceHome p-100-y">
        <div className="container-md">
          <div className="row">
            <div className="col-xxxl-6 col-sm-6 col-12 ">
              <div className="position-sticky top-25">
                <Title
                  minText={`CONTACT US`}
                  text={`Get in touch with us`}
                  textAlign={`text-sm-start text-center`}
                />
                <div className="text-second text-sm-start text-center fs-lg-16 fs-14 lh-35 m-xl-150-left m-sm-50-left m-sm-50-top m-10-top m-sm-0-bottom m-50-bottom">
                  We all know that nothing moves until someone makes a decision.
                  The first action is always in making the decision to proceed.
                  which most people overlook, we don’t do it intentionally or
                  with malice.
                </div>
              </div>
            </div>
            <div className="col-xxxl-4 col-sm-6 col-12 ">
              <div className="serviceDetails">
                {webContactFullData?.map((res,i) => (
                  <div className="companyDetails bg-primeExtraLight border-solid-primeDark-1 p-20 border-radius-10 cursor-pointer d-flex m-sm-50-bottom m-30-bottom" key={i}>
                    <div className="companyDetailsIcon fs-lg-40 fs-24 text-primeDark m-10-right">
                      <i class={res.icon}></i>
                    </div>
                    <div className="companyDetailsText">
                      <div className="text-primeDark fw-500 m-10-bottom fs-xl-24 fs-lg-22 fs-md-18 fs-14 lh-xxl-45">
                        {res.title}
                      </div>
                      <div className="footerSubDetail m-5-top fw-500">
                        {res?.info?.map((nextRes,index) => (
                          <Link
                            className="text-second fs-lg-22 fs-md-18 fs-14 lh-md-45 lh-30"
                            target="_blank"
                            to={nextRes.link}
                            key={index}
                          >
                            <div>{nextRes.text}</div>
                          </Link>
                        ))}
                      </div>
                      <div className="text-primeDark m-10-bottom fs-xl-20 fs-lg-18 fs-md-16 fs-14 lh-xxl-45">
                        {res?.notice && `( ${res?.notice} )`}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <GoogleMap />
      <ClientInquires />
    </div>
  );
};

export default Contact;
