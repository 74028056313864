const GoogleMap = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d232.43429461154338!2d72.91373900052263!3d21.233882216436704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868b29776e915311%3A0x9164ab33909a5d9d!2sMultikod%20Infosys%20%26%20Advance%20training!5e0!3m2!1sen!2sin!4v1737977526700!5m2!1sen!2sin"
        width="100%"
        height="600"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMap;
