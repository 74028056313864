import React, { useEffect } from "react";
import "./footer.scss";
import WhiteLogo from "../../../assets/images/big_logo_white.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWebContact } from "../../../redux/slice/webContactSlice";

const Footer = () => {
  const dispatch = useDispatch();
  const { webContactFullData, webContactIconData } = useSelector(
    (state) => state.webContact
  );

  useEffect(() => {
    dispatch(getWebContact());
  }, []);

  return (
    <footer className="p-50-y">
      <div className="container-md">
        <div className="startFooter bg-third border-radius-20 p-sm-80 p-40">
          <div className="row">
            <div className="col-xl-4 col-sm-6 col-12">
              <div className="companyLogo width-lg-200 width-150 d-sm-none m-50-bottom m-auto">
                <img src={WhiteLogo} alt="Multikod Logo" />
              </div>
              {webContactFullData?.map((res, i) => (
                <div
                  className={`companyDetails cursor-pointer d-flex ${
                    i != webContactFullData.length - 1 && "m-30-bottom"
                  }`}
                  key={i}
                >
                  <div className="companyDetailsIcon fs-lg-30 fs-24 text-light m-10-right">
                    <i className={res.icon}></i>
                  </div>
                  <div className="companyDetailsText fs-lg-16 fs-14">
                    <div className="text-light fw-500">{res.title}</div>
                    <div className="footerSubDetail m-5-top fw-300 lh-26">
                      {res?.info?.map((nextRes, index) => (
                        <Link
                          className=" text-gray"
                          target="_blank"
                          to={nextRes.link}
                          key={index}
                        >
                          <div>{nextRes.text}</div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-2 d-xl-block d-none"></div>
            <div className="col-sm-6 col-12 m-sm-0-top">
              <div className="companyLogo width-lg-200 width-150 d-sm-block d-none">
                <img src={WhiteLogo} alt="Multikod Logo" />
              </div>
              <div className="companySocialMedia m-50-top d-flex flex-wrap justify-content-sm-start justify-content-center">
                {webContactIconData?.map((res,i) => (
                  <Link to={res.dairectLink} target="_blank" key={i}>
                    <div className="socialMediaBox trans-3 cursor-pointer m-10-right m-10-bottom hw-lg-60 hw-50 border-solid-light-1 border-round-50 d-flex align-items-center justify-content-center">
                      <i className={res.icon}></i>
                    </div>
                  </Link>
                ))}
              </div>

              <div className="copyrightOfCompany text-light m-30-top fs-lg-14 fs-12 fw-300 text-sm-start text-center">
                <span className="text-gray">2024 © </span>
                <span>Multikod Infosys and Advance Training</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
