import Slider from "react-slick";
import Title from "../../../Extra/Title/Title";
import "./review.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  webClientReviewsGet,
  webReviewsAdd,
  webStudentReviewsGet,
} from "../../../../redux/slice/webReviewsSlice";
import { baseURL } from "../../../../utils/config";
import WebBanner from "../WebBanner.js/WebBanner";
import Input, {
  clearFormFields,
  Image,
  objectToFormData,
  Select,
  submitData,
  Textarea,
} from "../../../Extra/Inputs/Input";
import Button from "../../../Extra/Button/Button";
import { useNavigate } from "react-router-dom";

const Review = () => {
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    let addWebReviews = submitData(e);
    if (addWebReviews) {
      const formData = objectToFormData(addWebReviews);
      try {
        dispatch(webReviewsAdd(formData)).unwrap();
        clearFormFields("reviewForm");
      } catch (err) {
        throw err;
      }
    }
  };

  return (
    <section className="allReviews">
      <WebBanner
        pageTitle={`Reviews`}
        title={`Your Thoughts, Our Best Motivation`}
        highlightText={["Motivation"]}
      />
      <div className="container-md m-100-y">
        <Title minText={`REVIEW FORM`} text={`Share Your Experience With Us`} />
        <div className="contectDetails text-center m-100-top">
          <form id="reviewForm" onSubmit={(e) => handleSubmit(e)}>
            <div className="row justify-content-center">
              <div className="col-12 m-30-bottom d-flex justify-content-center">
                <Image
                  id={`image`}
                  name={`image`}
                  errorMessage={`Enter Image`}
                />
              </div>
              <div className="col-sm-6 col-12 m-30-bottom">
                <Input
                  type={`text`}
                  name={`name`}
                  id={`name`}
                  placeholder={`Full Name`}
                  errorMessage={`Enter Your Full Name`}
                />
              </div>
              <div className="col-sm-6 col-12 m-30-bottom">
                <Select
                  id={`type`}
                  name={`type`}
                  placeholder={`What's You Are`}
                  option={[
                    { name: "Client", value: 1 },
                    { name: "Student", value: 2 },
                  ]}
                  errorMessage={`Enter Gender`}
                />
              </div>
              <div className="col-12 m-30-bottom">
                <Textarea
                  name={`reviews`}
                  id={`reviews`}
                  row={6}
                  placeholder={`Reviews`}
                  errorMessage={`Enter Your Reviews`}
                />
              </div>
              <div className="col-12">
                <Button text={`Send Message`} className={`m-50-top`} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
export default Review;

export const ReviewSlide = ({ text, minText, type }) => {
  const navigate = useNavigate();
  return (
    <section className="clientReview p-80-y">
      <Title minText={minText} text={text} />

      {type == 1 ? <ClientReviews /> : type == 2 && <StudentReviews />}

      <div className="col-12 text-center">
        <Button text={`Send Review`} onClick={() => navigate("/reviews")} />
      </div>
    </section>
  );
};

export const StudentReviews = () => {
  const { webStudentReviews } = useSelector((state) => state.webReviews);
  const [data, setData] = useState([]);
  const options = {
    arrows: false,
    infinite: data.length <= 1 ? false : true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(webStudentReviewsGet());
  }, []);
  useEffect(() => {
    setData(webStudentReviews);
  }, [webStudentReviews]);

  return (
    <div className="row justify-content-center p-70-y">
      <div className="col-xxxl-6 col-xxl-7 col-xl-8 col-md-9 col-sm-10 col-11">
        <Slider className="owl-theme" {...options} id="clientReview">
          {data.map((res,i) => (
            <div className="item" key={i}>
              <div className="reviewDetails">
                <div className="reviewText text-gray fs-lg-20 fs-sm-18 fs-14 lh-sm-40 lh-30 text-overflow-md-4 text-overflow-5">
                  {res?.reviews}
                </div>
                <div className="userDetails m-20-top d-flex align-items-center">
                  <div className="userImage hw-60 border-round-50 overflow-hidden">
                    <img src={baseURL + res?.image} alt="Multikod Student" />
                  </div>
                  <div className="userName fw-600 fs-xxl-22 fs-md-20 fs-sm-16 fs-14 m-20-left">
                    {res?.name}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export const ClientReviews = () => {
  const { webClientReviews } = useSelector((state) => state.webReviews);
  const [data, setData] = useState([]);
  const options = {
    arrows: false,
    infinite: data.length <= 1 ? false : true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(webClientReviewsGet());
  }, []);
  useEffect(() => {
    setData(webClientReviews);
  }, [webClientReviews]);

  return (
    <div className="row justify-content-center p-70-y">
      <div className="col-xxxl-6 col-xxl-7 col-xl-8 col-md-9 col-sm-10 col-11">
        <Slider className="owl-theme" {...options} id="clientReview">
          {data.map((res,i) => (
            <div className="item" key={i}>
              <div className="reviewDetails">
                <div className="reviewText text-gray fs-lg-20 fs-sm-18 fs-14 lh-sm-40 lh-30 text-overflow-md-4 text-overflow-5">
                  {res?.reviews}
                </div>
                <div className="userDetails m-20-top d-flex align-items-center">
                  <div className="userImage hw-60 border-round-50 overflow-hidden">
                    <img src={baseURL + res?.image} alt="Multikod Clients" />
                  </div>
                  <div className="userName fw-600 fs-xxl-22 fs-md-20 fs-sm-16 fs-14 m-20-left">
                    {res?.name}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
