import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  isLoading: false,
};

const loaderSlice = createSlice({
  name: "loaderSlice",
  initialState,
  reducers: {
    openLoader(state, action) {
      state.isLoading = true;
    },
    closeLoader(state, action) {
      state.isLoading = false;
    },
  },
});
export default loaderSlice.reducer;
export const { openLoader, closeLoader } = loaderSlice.actions;
