import Header from "./pages/global_components/Header/Header";
import "./assets/scss/default.min.scss";
import "./pages/global_components/globle.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import Loader from "./pages/Extra/Loader/Loader";
import { useDispatch } from "react-redux";
import { closeLoader, openLoader } from "./redux/slice/loaderSlice";
import { Route, Routes, useLocation } from "react-router-dom";
import Cursor from "./pages/Extra/Cursor/Cursor";
import Home from "./pages/components/Home/Home";
import Portfolio from "./pages/components/Portfolio/Portfolio";
import PortfolioShow from "./pages/components/Portfolio/PortfolioShow";
import Error404 from "./pages/components/Error404/Error404";
import Courses from "./pages/components/Courses/Courses";
import CourseShow from "./pages/components/Courses/CourseShow";
import Footer from "./pages/global_components/Footer/Footer";
import ScrollTop from "./pages/Extra/ScrollTop/ScrollTop";
import About from "./pages/components/About/About";
import Contact from "./pages/components/Contact/Contact";
import { ToastContainer } from "react-toastify";
import Inquires from "./pages/components/Inquries/Inquires";
import Review from "./pages/global_components/Extra-Components/Review/Review";
import SEO from "./pages/global_components/Extra-Components/SEO";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [locate, setLocate] = useState(location.pathname);

  useEffect(() => {
    setLocate(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (!locate) return;

    dispatch(openLoader());

    setTimeout(() => {
      dispatch(closeLoader());
    }, 3000);
  }, [locate]);

  return (
    <div className="">
      <SEO />
      <ScrollManager />
      <Cursor />
      <div className="mainContant">
        <div id="detailBar">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route
              path="/portfolio/:portfolioName"
              element={<PortfolioShow />}
            />
            <Route path="/courses" element={<Courses />} />
            <Route path="/courses/:courseName" element={<CourseShow />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/inquires" element={<Inquires />} />
            <Route path="/reviews" element={<Review />} />
            <Route path="*" element={<Error404 />} />
            <Route path="/courses/*" element={<Error404 />} />
            {/* 
            <Route path="/products" element={<Product />} />
            <Route path="/products/productsShow" element={<ProductShow />} />
         */}
          </Routes>
          <Footer />
          <ScrollTop />
          <ToastContainer />
        </div>
      </div>
      <Loader />
    </div>
  );
}

export default App;

export const ScrollManager = () => {
  const location = useLocation();

  useEffect(() => {
    const savedPosition = sessionStorage.getItem(location.key);
    if (savedPosition) {
      window.scrollTo(0, parseInt(savedPosition, 10)); // Restore scroll position
    } else {
      window.scrollTo(0, 0); // Scroll to top for new routes
    }
  }, [location.key]);

  useEffect(() => {
    const saveScrollPosition = () => {
      sessionStorage.setItem(location.key, window.scrollY.toString());
    };
    window.addEventListener("beforeunload", saveScrollPosition);
    return () => {
      window.removeEventListener("beforeunload", saveScrollPosition);
    };
  }, [location.key]);

  return null;
};
