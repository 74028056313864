import React from "react";
import { useSelector } from "react-redux";
import "./loader.scss";

const Loader = () => {
  const { isLoading } = useSelector((state) => state.loader);

  return (
    <>
      {isLoading && (
        <div className="mainLoader">
          <div className="loader">
            <div className="loadWord">
              <span>L</span>
              <span>O</span>
              <span>A</span>
              <span>D</span>
              <span>I</span>
              <span>N</span>
              <span>G</span>
            </div>
            <div className="loaderLine"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
