import React from "react";
import WebBanner from "../../global_components/Extra-Components/WebBanner.js/WebBanner";
import About1 from "../../../assets/images/about/about1.png";
import About2 from "../../../assets/images/about/about2.png";
import About3 from "../../../assets/images/about/about3.png";
import About4 from "../../../assets/images/about/about4.png";
import Highlights from "../../Extra/Highlights";

const About = () => {
  return (
    <div>
      <WebBanner
        pageTitle={`ABOUT US`}
        title={`Crafting Web Solutions & Empowering Skills`}
        highlightText={["Web Solutions"]}
      />
      <section className="m-100-y">
        <AboutDetails
          theme={`light`}
          image={About1}
          title={`ABOUT`}
          text={`At Multikod Infosys & Advance Training, we specialize in creating innovative and visually captivating websites using React and Node.js. Our dedicated team transforms ideas into digital masterpieces, ensuring seamless user experiences. Beyond development, we offer hands-on training to equip aspiring developers with essential.`}
          highlights={[
            "Multikod Infosys & Advance Training",
            "React and Node.js.",
          ]}
        />
        <AboutDetails
          theme={`dark`}
          image={About2}
          title={`JOURNEY`}
          text={`Multikod Infosys & Advance Training was founded with a passion for creativity and innovation. We started as a graphics design company, and after two successful years, expanded into the IT sector. Our expertise in web design, development, and application development flourished during this time.`}
          highlights={["Multikod Infosys & Advance Training"]}
        />
        <AboutDetails
          theme={`light`}
          image={About3}
          title={`MISSION`}
          text={`To be a global leader in technology, design, and education, fostering innovation and empowering communities to excel in the digital era. We strive to make Multikod Infosys & Advance Training a name synonymous with creativity, quality, and progress.`}
          highlights={["Multikod Infosys & Advance Training"]}
        >
          <ul className="m-30-left text-start">
            <li style={{ listStyle: "disc" }} className="m-20-bottom">
              To deliver cutting-edge IT and design solutions that drive success
              for our clients.
            </li>
            <li style={{ listStyle: "disc" }} className="m-20-bottom">
              To provide world-class education and hands-on training that
              bridges the gap between talent and industry demand.
            </li>
            <li style={{ listStyle: "disc" }} className="m-20-bottom">
              To inspire innovation and empower individuals to achieve their
              professional goals.
            </li>
          </ul>
        </AboutDetails>
        <AboutDetails theme={`dark`} image={About4} title={`VALUES`}>
          <ul className="m-30-left text-start text-light">
            <li style={{ listStyle: "disc" }} className="m-20-bottom">
              <b>Innovation :</b> At MultiKod, creativity meets technology. We
              constantly innovate to deliver top-notch solutions.
            </li>
            <li style={{ listStyle: "disc" }} className="m-20-bottom">
              <b>Excellence :</b> Quality is at the core of everything we do,
              ensuring our clients and students achieve their best.
            </li>
            <li style={{ listStyle: "disc" }} className="m-20-bottom">
              <b>Integrity :</b> We are committed to transparency, honesty, and
              ethical practices in every endeavor.
            </li>
            <li style={{ listStyle: "disc" }} className="m-20-bottom">
              <b>Collaboration :</b> Partnering with businesses, educators, and
              students, we believe in the power of teamwork.
            </li>
          </ul>
        </AboutDetails>
      </section>

      <ServiceAbout />
    </div>
  );
};

export default About;

const AboutDetails = (props) => {
  const { theme, image, title, text, highlights } = props;
  return (
    <div
      className={`aboutData ${
        theme == "dark" && "bg-third"
      } p-lg-100-y p-md-10-y p-30-y`}
    >
      <div className="container-md">
        <div className="row align-items-center justify-content-center">
          <div
            className={`col-lg-5 col-md-6 col-sm-9 col-11 ${
              theme == "dark" ? "order-md-1" : "order-0"
            }`}
          >
            <div className="aboutPropsData">
              <div className="imageAboutSection">
                <img src={image} alt="About Us Images" />
              </div>
            </div>
          </div>
          <div
            className={`col-lg-7 col-md-6 col-sm-9 col-11 p-20 ${
              theme == "dark" ? "order-md-0" : "order-1"
            }`}
          >
            <div
              className={`aboutTitle fs-xxl-120 lh-xxl-180 fs-xl-90 lh-xl-150 fs-sm-60 fs-40 lh-120 fw-700 ls-2 ${
                theme == "dark" ? "text-light" : "text-second"
              } text-md-start text-center`}
            >
              {title}
            </div>
            <div className="aboutDescription">
              <Highlights
                text={text}
                highlights={highlights}
                className={`${
                  theme == "dark" ? "text-light" : "text-second"
                } fs-xxxl-26 fs-xxl-24 fs-xl-20 fs-lg-18 fs-16 lh-xxxl-70 lh-xxl-55 lh-xl-45 lh-lg-40 lh-35 fw-500 m-20-bottom text-md-start text-center`}
                highlightColor={`${
                  theme == "dark" ? "text-primeDarkLight" : "text-primeDark"
                }`}
              />
              <div className="aboutText fs-xxxl-26 fs-xxl-24 fs-xl-20 fs-lg-18 fs-16 lh-xxxl-70 lh-xxl-55 lh-xl-45 lh-lg-40 lh-35 fw-500 m-20-bottom text-md-start text-center">
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ServiceAbout = () => {
  const serviceData = [
    {
      title: "Academic Services",
      data: [
        "Web Design",
        "Web Development",
        "Full Stack Development",
        "React JS Development",
        "Node JS Development",
        "Graphics Design",
        "UI/UX Design",
      ],
    },
    {
      title: "Development Services",
      data: [
        "Web Applications",
        "Mobile Applications",
        "E-commerce Websites",
        "Restaurant Management",
        "CRM Software",
        "HR Management Systems",
        "Admin Panels",
        "Salon & Spa Website",
        "Hotel & Cafe Website",
      ],
    },
    {
      title: "Graphics Services",
      data: [
        "Festival Post Design",
        "Banner Design",
        "Visiting Card Design",
        "Invitation Card Design",
        "Marriage Card Design",
        "Logo Design",
        "Business Post",
      ],
    },
  ];

  return (
    <div className="serviceDetails text-uppercase p-50-y">
      <div className="container-md">
        <div className="startServiceDetails">
          <div className="row">
            {serviceData.map((res, i) => (
              <div
                className="col-lg-4 col-sm-6 col-12 text-lg-start text-center m-50-bottom"
                key={i}
              >
                <div className="showService">
                  <div className="serviceHead text-primeDark fw-600 ls-1 fs-xxl-24 fs-xl-20 fs-lg-18 fs-16 m-30-bottom">
                    {res.title}
                  </div>
                  <ul className="serviceText">
                    {res?.data?.map((resData, index) => (
                      <li
                        key={index}
                        className="fs-xxl-24 fs-xl-20 fs-lg-18 fs-16 lh-xxl-55 lh-xl-45 lh-lg-40 lh-35 fw-500"
                      >
                        {resData}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
