import React, { useEffect, useRef, useState } from "react";
import Title from "../../Extra/Title/Title";
import "./portfolio.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWebPortfolioCategoryWise } from "../../../redux/slice/webPortfolioSlice";
import { baseURL } from "../../../utils/config";
import Isotope from "isotope-layout";
import Button from "../../Extra/Button/Button";

const PortfolioCard = () => {
  const { webPortfolio, webPortfolioCategory } = useSelector(
    (state) => state.webPortfolio
  );

  const [data, setData] = useState([]);
  const [showPage, setShowPage] = useState(12);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWebPortfolioCategoryWise());
  }, []);
  useEffect(() => {
    setData([
      ...webPortfolio,
      ...webPortfolio,
      ...webPortfolio,
      ...webPortfolio,
    ]);
  }, [webPortfolio]);

  // const isotope = useRef();
  const [isotope, setIsotope] = useState(null);
  const [filterKey, setFilterKey] = useState("*");

  useEffect(() => {
    setIsotope(
      new Isotope(".filter-container", {
        itemSelector: ".filter-item",
        layoutMode: "fitRows",
      })
    );
  }, [data]);

  useEffect(() => {
    if (isotope) {
      filterKey === "*"
        ? isotope.arrange({ filter: `*` })
        : isotope.arrange({ filter: `.${filterKey}` });
    }
  }, [isotope, filterKey]);

  return (
    <section className="portfolioCard p-100-y">
      <div className="container-md">
        <div className="row">
          <div className="col-lg-7 col-sm-6 col-12 order-sm-0 order-1">
            <div className={`portfolioCardDetails m-50-y`} key={1}>
              <div className="row filter-container">
                {data?.slice(0, showPage).map((res, i) => (
                  <div
                    className={`col-lg-6 col-12 m-25-bottom filter-item ${res?.categoryName}`}
                    key={i}
                  >
                    <PortfolioBanner
                      image={res?.homePageImage}
                      title={res?.title}
                      type={res?.categoryName}
                      link={res?.portfolioLink}
                      className={i}
                    />
                  </div>
                ))}
              </div>
            </div>

            {data.length > showPage && (
              <div
                className="addDataButton border-solid-primeDark-2 border-round-50 d-flex justify-content-center align-items-center hw-50 m-auto text-primeDark m-xl-120-y m-sm-90-y m-50-y cursor-pointer"
                onClick={() => setShowPage(showPage + 3)}
              >
                <i className="fa-solid fa-angles-down"></i>
              </div>
            )}
          </div>
          <div className="col-lg-5 col-sm-6 col-12 order-sm-1 order-0">
            <div className="position-sticky top-25">
              <div className="startPortfolio">
                <Title
                  minText={`IMPRESSIVE WORK`}
                  text={`Our Latest Previous Projects`}
                />
                <div className="portfolioCategory p-lg-50-y p-10-y">
                  <div className="categoryBoard fs-xl-18 fs-lg-16 fs-sm-14 fs-12 cursor-pointer text-primeDark fw-500 overflow-hidden">
                    <div className="categoryBoardMain d-flex flex-wrap flex-lg-row flex-sm-column">
                      <div
                        className={`p-xxl-20 p-10 d-inline-block `}
                        onClick={() => setFilterKey("*")}
                      >
                        <Button
                          text={`All`}
                          className={`btnPrimeLight p-xl-15 p-10 fs-xl-16 fs-14 ${
                            filterKey == "*" && "btnActive"
                          }`}
                        />
                      </div>
                      {webPortfolioCategory.map((res, i) => (
                        <div
                          key={i}
                          className={`p-xxl-20 p-10 d-inline-block`}
                          onClick={() => setFilterKey(res)}
                        >
                          {/* {res} */}
                          <Button
                            text={res}
                            className={`btnPrimeLight p-xl-15 p-10 fs-xl-16 fs-14 ${
                              filterKey == res && "btnActive"
                            }`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PortfolioCard;

export const PortfolioBanner = ({ image, title, type, link, className }) => {
  return (
    <div className={`mainPoerfolio ${className}`}>
      <Link to={`/portfolio/${link}`}>
        <div className="cursor-pointer text-start fs-xxl-16 fs-xl-18 fs-lg-14 fs-16">
          <div className="portfolioImage border-radius-10 overflow-hidden height-500">
            <img src={baseURL + image} alt="portfolio banner" />
          </div>
          <div className="portfolioTitle m-10-y fw-600 gradientText">
            {title}
          </div>
          <div className="portfolioType fw-500 text-dark">{type}</div>
        </div>
      </Link>
    </div>
  );
};
