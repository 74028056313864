import React from "react";
import Title from "../../Extra/Title/Title";
import Button from "../../Extra/Button/Button";
import Input, {
  clearFormFields,
  generateNum,
  Select,
  submitData,
} from "../../Extra/Inputs/Input";
import { inquiryAdd } from "../../../redux/slice/webInquiresSlice";
import { useDispatch, useSelector } from "react-redux";

const StudentInquries = () => {
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    let addWebMessages = submitData(e);
    addWebMessages = { ...addWebMessages, type: 2, stauts: 1 };
    if (addWebMessages) {
      try {
        const res = await dispatch(inquiryAdd(addWebMessages)).unwrap();
        res.status && clearFormFields("studentInquireForm");
      } catch (err) {
        throw err;
      }
    }
  };
  const { course } = useSelector((state) => state.webInquires);

  const options = course.map((res) => res.courseName);

  return (
    <section className="contectHome p-100-y text-center">
      <div className="container-md">
        <Title
          minText={`COURSE INQUIRES`}
          text={`Join the Tech Revolution - Inquire About Our Classes`}
        />
        <div className="contectDetails text-center m-100-top">
          <form id="studentInquireForm" onSubmit={(e) => handleSubmit(e)}>
            <div className="row m-100-bottom justify-content-center">
              <div className="col-sm-4 col-12 m-30-bottom">
                <Input
                  type={`text`}
                  name={`name`}
                  id={`name`}
                  placeholder={`Full Name`}
                  errorMessage={`Enter Your Full Name`}
                />
              </div>
              <div className="col-sm-4 col-12 m-30-bottom">
                <Input
                  type={`number`}
                  name={`mobileNo`}
                  id={`mobileNo`}
                  placeholder={`Mobile Number`}
                  errorMessage={`Enter Your Mobile Number`}
                />
              </div>
              <div className="col-sm-4 col-12 m-30-bottom">
                <Input
                  type={`text`}
                  name={`inquiryId`}
                  id={`inquiryId`}
                  placeholder={`Form ID`}
                  errorMessage={`Enter Your Form ID`}
                  validation={`^[a-zA-Z0-9]{6,6}$`}
                  validationError={`Invalid Form Code`}
                  activeIcon={`ri-information-line`}
                  activClick={() => generateNum(6, "inquiryId")}
                  readOnly
                />
              </div>
            </div>
            <div className="row m-100-bottom">
              <div className="col-6">
                <Select
                  id={`gender`}
                  name={`gender`}
                  placeholder={`Gender`}
                  option={["Male", "Female"]}
                  errorMessage={`Enter Gender`}
                />
              </div>
              <div className="col-6">
                <Select
                  id={`course`}
                  name={`course`}
                  placeholder={`Course`}
                  option={options}
                  errorMessage={`Enter Course`}
                />
              </div>
            </div>

            <div className="col-12">
              <Button text={`Send Message`} className={`m-50-top`} />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default StudentInquries;
