import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../axiosApi";
import { setToast } from "../../utils/toast";

const initialState = {
  webMessages: [],
  course: [],
  isLoading: false,
  isSkeleton: false,
};
export const createWebMessages = createAsyncThunk(
  "webMessages/createWebMessages",
  async (payload) => {
    return apiInstance.post("webMessages/createWebMessages", payload);
  }
);

export const courseGet = createAsyncThunk("course/show", async (payload) => {
  return apiInstance.get(
    `course/show?page=${payload.page}&limit=${payload.limit}&search=${payload?.search}`
  );
});

export const inquiryAdd = createAsyncThunk(
  "inquiry/create",
  async (payload) => {
    return apiInstance.post("inquiry/create", payload);
  }
);

const webInquiresSlice = createSlice({
  name: "webInquiresSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // webMessagesCreate
    builder.addCase(createWebMessages.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createWebMessages.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.webMessages.unshift(action.payload.webMessages);
        setToast("success", "Message Send");
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(createWebMessages.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(courseGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(courseGet.fulfilled, (state, action) => {
      state.course = action.payload.course;
      state.isSkeleton = false;
    });
    builder.addCase(courseGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // inquiryCreate
    builder.addCase(inquiryAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(inquiryAdd.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        setToast("success", "Inquery Send Successfully");
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(inquiryAdd.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webInquiresSlice.reducer;
