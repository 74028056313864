import { Helmet } from "react-helmet-async";

const SEO = ({
  title = "MULTIKOD INFOSYS & ADVANCE TRAINING | Web & App Development, Academic Training, Graphic Design | multikod",
  description = "MULTIKOD INFOSYS & ADVANCE TRAINING offers expert Web & Mobile Development, Graphics Design, and IT Training. We build web applications, CRM, e-commerce websites, and provide academic courses in Full Stack, React, Node.js, UI/UX, and more.",
  keywords = "Web Development, Mobile Applications, E-commerce, CRM Software, Graphics Design, IT Training, Full Stack Development, React JS, Node JS, UI/UX, Multikod Infosys, Multicode",
  url = "https://multikodit.com/",
}) => {
  const jsonLD = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Multikod Infosys and Advance Training",
    url: url,
    logo: "https://multikodit.com/logo-multikod192.png",
    description: description,
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+91-8866625957",
      contactType: "customer service",
      areaServed: "IN",
      availableLanguage: ["en", "hi"],
    },
    sameAs: [
      "https://www.facebook.com/MultikodInfosys/",
      "https://in.linkedin.com/company/multikod-infosys-and-advance-training",
      "https://www.instagram.com/multikod_infosys/",
    ],
  };
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content="Multikod Infosys and Advance Training" />
      <meta name="robots" content="index, follow" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="format-detection" content="telephone=8866625957" />

      {/* Open Graph (OG) for Social Media Sharing */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="multikod" />
      <meta
        property="og:image"
        content="https://multikodit.com/logo-multikod192.png"
      />
      <link rel="apple-touch-icon" href="/logo-multikod192.png" />

      {/* Twitter Card */}
      <meta
        name="twitter:card"
        content="https://multikodit.com/logo-multikod512.png"
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content="https://multikodit.com/logo-multikod192.png"
      />

      {/* Canonical URL */}
      <link rel="canonical" href={url} />

      <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
    </Helmet>
  );
};

export default SEO;
