import React, { useEffect, useState } from "react";
import "./home.scss";
import { useDispatch, useSelector } from "react-redux";
import { getWebSlider } from "../../../redux/slice/webSliderSlice";

const TopSlider = () => {
  const { webSlider } = useSelector((state) => state.webSlider);

  const dispatch = useDispatch();

  const [data, setData] = useState([
    {
      _id: "678f816a79ecc79cbab0902a",
      title: "PREPARING FUTURE",
      highlight: "LEADERS!",
      smallTitle: "Our Focus?",
      createdAt: "2025-01-21T11:13:46.237Z",
      updatedAt: "2025-01-21T11:17:13.744Z",
    },
  ]);

  useEffect(() => {
    dispatch(getWebSlider());
  }, []);
  useEffect(() => {
    setData(webSlider);
  }, [webSlider]);

  return (
    <section className="startHeader bg-third topSlider p-50-bottom p-30-top">
      <div className="container-md">
        <div className="startTopSlider">
          <div className="mainSliderBox  position-relative">
            <div className="row p-100-md-x p-lg-50-x p-xl-70-y p-sm-20-y w-100">
              <div className="col-12">
                <div
                  className={`sliderText p-lg-40-left text-md-start text-center`}
                >
                  <AnimatedTextSequence
                    smallTitleClassName={`headTitle text-light fs-xxl-80 lh-xxl-120 fs-xl-60 lh-xl-80 fs-lg-40 lh-lg-40 fs-30 lh-50`}
                    titleClassName={`text-light ls-1 fs-xxl-100 lh-xxl-140 fs-xl-80 lh-xl-100 fs-lg-60 lh-lg-80 fs-40 lh-60 fw-700`}
                    highlightClassName={`text-primeDarkLight ls-1 fs-xxl-100 lh-xxl-120 fs-xl-80 lh-xl-100 fs-lg-60 lh-lg-80 fs-40 lh-60 fw-700 `}
                    texts={data}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopSlider;

export const AnimatedTextSequence = ({
  texts,
  titleClassName,
  smallTitleClassName,
  highlightClassName,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prev) => (prev < texts.length - 1 ? prev + 1 : 0));
    }, 2500);

    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <div className="animated-text-container">
      {texts.length > 0 && (
        <div className="opacity-0">
          <div className={`${smallTitleClassName}`}>{texts[0].smallTitle}</div>
          <div className={`${titleClassName}`}>{texts[0].title}</div>
          <div className="heading-text-style-7">
            <div className="heading-anim-text">
              <span className={`${highlightClassName}`}>
                {texts[0].highlight}
              </span>
            </div>
          </div>
        </div>
      )}
      {texts.map((text, index) => (
        <div
          key={index}
          className={`animated-text ${index === activeIndex ? "active" : ""}`}
        >
          <div className={`${smallTitleClassName}`}>{text.smallTitle}</div>
          <div className={`${titleClassName}`}>{text.title}</div>
          <AnimatedText
            text={text.highlight}
            activeIndex={activeIndex}
            className={highlightClassName}
          />
        </div>
      ))}
    </div>
  );
};

export const AnimatedText = ({ text, activeIndex, className }) => {
  const [animatedLetters, setAnimatedLetters] = useState([]);

  useEffect(() => {
    const letters = text.split("").map((letter) => ({
      char: letter,
      animatingIn: false,
    }));
    setAnimatedLetters(letters);

    let delay = 0;
    letters.forEach((_, i) => {
      setTimeout(() => {
        setAnimatedLetters((prev) =>
          prev.map((l, idx) => (idx === i ? { ...l, animatingIn: true } : l))
        );
      }, delay);
      delay += 100; // Adjust delay between letters
    });
  }, [text, activeIndex]);

  return (
    <div className="heading-text-style-7">
      <div className="heading-anim-text">
        {animatedLetters.map(({ char, animatingIn }, index) => (
          <span
            key={index}
            className={`letter ${
              animatingIn ? "letter-anim-in" : ""
            } ${className}`}
          >
            {char}
          </span>
        ))}
      </div>
    </div>
  );
};
