import React, { useEffect } from "react";
import "./header.scss";
import TitleHeader from "./TitleHeader";
import Navigation from "./Navigation";
import { useDispatch } from "react-redux";
import { courseGet } from "../../../redux/slice/webInquiresSlice";
const Header = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      courseGet({
        page: 0,
        limit: 500,
        search: "",
      })
    );
  }, []);

  return (
    <header className="headerArea">
      <div className="bg-third">
        <TitleHeader />
        <Navigation />
      </div>
    </header>
  );
};

export default Header;
