import React, { useEffect, useState } from "react";
import Title from "../../Extra/Title/Title";
import { webServiceGet } from "../../../redux/slice/webServiceSlice";
import { useDispatch, useSelector } from "react-redux";
import { baseURL } from "../../../utils/config";
import Button from "../../Extra/Button/Button";

const ServiceHome = () => {
  const { webService } = useSelector((state) => state.webService);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(webServiceGet());
  }, []);

  useEffect(() => {
    setData(webService);
  }, [webService]);

  return (
    <section className="serviceHome p-100-y bg-third">
      <div className="container-md">
        <div className="row">
          <div className="col-xl-5 col-lg-4 col-sm-6 col-12 ">
            <div className="position-sticky top-25">
              <Title
                minText={`BEST FEATURES`}
                text={`Our Services`}
                textAlign={`text-sm-start text-center`}
                fontsClass={`text-light`}
              />
              <div className="text-gray text-sm-start text-center fs-lg-16 fs-14 lh-35 m-xl-150-left m-sm-50-left m-sm-50-top m-10-top m-sm-0-bottom m-50-bottom">
                We offer end-to-end digital services, including web and app
                development, stunning graphics design, intuitive UI/UX, secure
                e-commerce solutions, and innovative product development,
                tailored to bring your ideas to life and grow your business.
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-8 col-sm-6 col-12 ">
            <div className="serviceDetails">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-xsm-9 col-12">
                  {data.map(
                    (res, i) =>
                      i % 2 == 0 && (
                        <ServiceCategories
                          image={res.image}
                          title={res.serviceName}
                          des={res.serviceDescription}
                          key={i}
                        />
                      )
                  )}
                </div>
                <div className="col-lg-6 col-xsm-9 col-12 m-lg-100-top">
                  {data.map(
                    (res, i) =>
                      i % 2 == 1 && (
                        <ServiceCategories
                          image={res.image}
                          title={res.serviceName}
                          des={res.serviceDescription}
                          key={i}
                        />
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceHome;

export const ServiceCategories = ({ image, title, des }) => {
  const url = baseURL + image;
  const imageURL = url.replace(/\\/g, "/");
  return (
    <div className="catrgoryOfService border-solid-second-1 border-radius-20 p-xxl-50-x p-30-x p-xxl-60-y p-40-y trans-3 cursor-pointer m-30-bottom position-relative">
      <div className="serviceText position-relative z-1">
        <div className="serviceImage hw-sm-60 hw-40 m-20-sm-bottom m-40-bottom">
          <img
            src={imageURL}
            alt="service"
            height={`100%`}
            style={{ objectFit: "contain", width: "auto" }}
          />
        </div>
        <div className="fw-600 fs-xxl-30 fs-lg-24 fs-22 text-uppercase text-light ls-1">
          {title}
        </div>
        <div className="text-light text-overflow-7 fs-xxl-18 fs-lg-16 fs-14 lh-35 m-lg-30-top m-sm-20-top m-15-top">
          {des}
        </div>
        <div className="serviceBtn m-15-top">
          <Button
            icon={`ri-arrow-right-line`}
            className={`btnLightBorder border-round-50`}
          />
        </div>
      </div>
      <div
        className="bottomServImage hw-60 m-20-bottom position-absolute bottom-0 right-20 hw-sm-60 hw-40 z-1"
        style={{
          maskImage: `url(${imageURL})`,
          WebkitMaskImage: `url(${imageURL})`,
        }}
      ></div>
    </div>
  );
};
